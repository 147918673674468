import {
  ButtonBack,
  ButtonNext, CarouselProvider,

  Slide, Slider
} from "pure-react-carousel"
import React from "react"

const SSlider = ({ slides }) => (
  <CarouselProvider
    naturalSlideHeight={600}
    naturalSlideWidth={800}
    totalSlides={slides.length}
    infinite
    isPlaying
  >
    <Slider>
      {slides.map((slide, index) => (
        <Slide index={index} key={index}>
          {slide}
        </Slide>
      ))}
    </Slider>
    <ButtonBack />
    <ButtonNext />
  </CarouselProvider>
)

export default SSlider
