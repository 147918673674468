import styled from "@emotion/styled"
import React from "react"
import { Col, Container, Row } from "react-grid-system"
import { black, borderRadius, secondary } from "../../utils/variables"
import Button from "../UI/button"
import { HeadingWrapper } from "../UI/typography"

const Discount = () => (
  <Container>
    <Row>
      <Col md={12}>
        <Wrapper>
          <HeadingWrapper>
            <Heading>Скидки оптовым покупателям</Heading>
          </HeadingWrapper>
          <div>
            <Button to="/catalog" accent>
              Продукция
            </Button>
          </div>
        </Wrapper>
      </Col>
    </Row>
  </Container>
)

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 75px;
  background: ${secondary}66;
  border: 1px solid ${black}4D;
  border-radius: ${borderRadius};
`

const Heading = styled.span`
  font-family: "Open Sans Bold";
  font-size: 25px;
  line-height: 0;
  font-weight: bold;
`

export default Discount
