import styled from "@emotion/styled"
import PropTypes from "prop-types"
import React, { useState } from "react"
import useInterval from "../../hooks/useInterval"
import { accent, borderRadius, red, secondary, white } from "../../utils/variables"
import { H2 } from "./typography"

const StringSlider = ({ strings }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const nextSlide = () => {
    if (currentSlide === strings.length - 1) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide(currentSlide + 1)
    }
  }
  useInterval(() => {
    nextSlide()
  }, 2000)
  return strings.length > 0 ? (
    <StringContainer>
      <StringSlide string={strings[currentSlide]} />
    </StringContainer>
  ) : null
}

const StringSlide = ({ string }) =>
  string
    .split(" ")
    .map((substr, id) => (
      <H2 key={substr + id} style={{ background: id === 0 ? accent : red, color: white, padding: "0 5px", fontSize: "25px" }}>{substr}</H2>
    ))

const StringContainer = styled.div`
  background: ${secondary};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  border-radius: ${borderRadius};
`

StringSlider.propTypes = {
  strings: PropTypes.arrayOf(PropTypes.string),
}

StringSlide.propTypes = {
  string: PropTypes.string,
}

export default StringSlider
