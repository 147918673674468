import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import { Col, Container, Row } from "react-grid-system"
import ConcreteMixer from "../../images/concrete-mixer.svg"
import Controller from "../../images/controller.svg"
import Temperature from "../../images/temperature.svg"
import bps from "../../utils/breakpoints"
import { black, primary } from "../../utils/variables"
import Button from "../UI/button"
import { H2, H3, HeadingWrapper } from "../UI/typography"

const HowWeDoIt = () => (
  <ContentWrapper>
    <Container>
      <Row>
        <Heading>
          <HeadingWrapper>
            <H2
              css={css`
                line-height: 22px;
              `}
            >
              Производство
            </H2>
          </HeadingWrapper>
        </Heading>
        <Item>
          <ConcreteMixer />
          <H3>Современное оборудование</H3>
          <ItemText>
            промышленные бетоносмесители роторного типа СБ-80 виброплощадки,
            отлаженные под производство мелкоштучных изделий из мелкозернистого
            бетона
          </ItemText>
        </Item>
        <Item>
          <Controller />
          <H3>Контроль на каждом этапе</H3>
          <ItemText>
            пропарочная камера, оснащенная промышленным программируемым
            микропроцессором для управления температурным режимом пропарки
            круглосуточно
          </ItemText>
        </Item>
        <Item>
          <Temperature />
          <H3>Оптимальная температура</H3>
          <ItemText>
            помещение, отапливаемое до температуры не менее 20°С в течение всего
            года
          </ItemText>
        </Item>
        <Col
          sm={12}
          css={css`
            text-align: center;
            ${bps.md`text-align: left;`}
          `}
        >
          <Button to="/o-kompanii" accent>
            Подробнее
          </Button>
        </Col>
      </Row>
    </Container>
  </ContentWrapper>
)

const ContentWrapper = styled.div`
  background: ${primary}66;
  padding-bottom: 40px;
`
export const Heading = styled(props => <Col sm={12} {...props} />)`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  ${bps.md`margin-bottom: 50px;`}
`

const Item = styled(props => <Col sm={12} md={4} {...props} />)`
  margin-bottom: 40px;
  svg {
    max-width: 120px;
  }
  text-align: center;
  ${bps.md`text-align: left;`}
`

const ItemText = styled.p`
  color: ${black};
`

export default HowWeDoIt
