import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import { File } from "react-feather"
import { Col, Container, Row } from "react-grid-system"
import QualityImage from "../../images/quality.svg"
import bps from "../../utils/breakpoints"
import { black } from "../../utils/variables"
import { H2, HeadingWrapper } from "../UI/typography"
import { Heading } from "./howWeDoIt"

const Quality = () => (
  <ContentWrapper>
    <Container>
      <Row
        css={css`
          ${bps.md`
            flex-direction: row-reverse;
          `}
        `}
      >
        <Heading>
          <HeadingWrapper>
            <H2
              css={css`
                line-height: 22px;
              `}
            >
              Гарантия качества
            </H2>
          </HeadingWrapper>
        </Heading>
        <Col sm={12} md={8}>
          <QualityImage />
        </Col>
        <Col
          sm={12}
          md={4}
          css={css`
            ul li:not(:last-child) {
              margin-bottom: 20px;
            }
          `}
        >
          <P>
            Изделия производятся в соответствии с ТУ 5747-019-04722888-2001 «Плитки бетонные тротуарные» Проектно-технологического института Госстроя России, а также ТУ 2018-1 «Плитка бетонная садовая. Технические условия».
          </P>
          <P>
            Соответствие техническим условиям всех требуемых показателей,
            подтверждено Протоколами испытаний:
          </P>
          <ul>
            <li>
              <FileLink href="docs/prochnost.jpg" target="_blank">
                <File />
                <FileName>Прочность на сжатие</FileName>
              </FileLink>
            </li>
            <li>
              <FileLink href="docs/vodopogloshenie.jpg" target="_blank">
                <File />
                <FileName>Водопоглощение</FileName>
              </FileLink>
            </li>
            <li>
              <FileLink href="docs/istiraemost.jpg" target="_blank">
                <File />
                <FileName>Истираемость</FileName>
              </FileLink>
            </li>
            <li>
              <FileLink href="docs/morozostoykost.jpg" target="_blank">
                <File />
                <FileName>Морозостойкость</FileName>
              </FileLink>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </ContentWrapper>
)

const ContentWrapper = styled.div`
  padding-bottom: 40px;
`

const P = styled.p`
  color: ${black};
`

const FileLink = styled.a`
  display: flex;
  align-items: center;
`
const FileName = styled.span`
  padding-left: 10px;
`

export default Quality
