import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Link from "../../utils/link"
import SSlider from "../UI/slider"

const SliderSection = () => (
  <StaticQuery
    query={graphql`
      query SliderImagesQuery {
        wordpressAcfOptions {
          id
          options {
            slider_content {
              image {
                localFile {
                  childImageSharp {
                    fluid(toFormat: WEBP) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              link
            }
          }
        }
      }
    `}
    render={data => {
      const images = data.wordpressAcfOptions.options.slider_content.map(
        item => {
          const url = item.link ? new URL(item.link) : null;
          return (
          <ConditionalWrapper
            condition={item.link}
            wrapper={children => <Link to={url.pathname}>{children}</Link>}
          >
            {item.image && <Img
              fluid={item.image.localFile.childImageSharp.fluid}
              alt={item.image.alt_text}
            />}
          </ConditionalWrapper>
          );
        }
      )
      return <>{images ? <SSlider slides={images}></SSlider> : ""}</>
    }}
  />
)

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

export default SliderSection
