import styled from "@emotion/styled"
import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { Search } from "react-feather"
import { Col, Container, Row } from "react-grid-system"
import bps from "../../utils/breakpoints"
import { SEARCH_PLACEHOLDER } from "../../utils/const"
import { SearchInputButton, SearchInputWrapper } from "../search/searchBox"
import { TextInput } from "../UI/input"
import StringSlider from "../UI/stringSlider"
import { H1 } from "../UI/typography"
import Slider from "./sliderSection"

const Hero = () => {
  const [searchTerm, setSearchTerm] = useState("")
  useEffect(() => {
    if (searchTerm) {
      const delaySearchNavigation = setTimeout(
        () => navigate("/search/", { state: { searchQuery: searchTerm } }),
        1000
      )

      return () => clearTimeout(delaySearchNavigation)
    }
  }, [searchTerm])
  return (
    <Container>
      <Wrapper align="center">
        <Col sm={12} md={8}>
          <Slider />
        </Col>
        <Text>
          <Heading>Производство и продажа в Ярославле:</Heading>
          <StringSlider
            strings={[
              "садовой плитки",
              "тротуарной плитки",
              "тактильной плитки",
              "бордюрного камня",
              "опорных подушек",
            ]}
          />
          <SearchInputWrapper>
            <TextInput
              type="text"
              onChange={e => setSearchTerm(e.target.value)}
              autoComplete="off"
              autoFocus
              placeholder={SEARCH_PLACEHOLDER}
            />
            <SearchInputButton>
              <Search />
            </SearchInputButton>
          </SearchInputWrapper>
        </Text>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled(props => <Row {...props} />)`
  margin-bottom: 100px;
  ${bps.md`
    flex-direction: row-reverse;
  `}
`
const Heading = styled(H1)`
  text-align: center;
  ${bps.md`
    text-align: left;
  `}
  margin-top: 30px;
`

const Text = styled(props => <Col sm={12} md={4} {...props} />)`
  text-align: center;
  ${bps.md`
    text-align: left;
  `}
`
export default Hero
