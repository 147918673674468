import React from "react"
import { Hidden } from "react-grid-system"
import Reveal from "react-reveal/Reveal"
import Address from "../components/sections/address"
import Discount from "../components/sections/discount"
import Hero from "../components/sections/hero"
import HowWeDoIt from "../components/sections/howWeDoIt"
import Quality from "../components/sections/quality"
import SEO from "../components/seo"



const IndexPage = () => (
  <>
    <SEO title="Главная" />
    <Reveal effect="fadeInRight">
      <Hero />
    </Reveal>
    <Reveal effect="fadeInRight">
      <HowWeDoIt />
    </Reveal>
    <Reveal effect="fadeInRight">
      <Quality />
    </Reveal>
    <Reveal effect="fadeInRight">
      <Address />
    </Reveal>
    <Hidden xs sm>
      <Reveal effect="fadeInRight">
        <Discount />
      </Reveal>
    </Hidden>
  </>
)

export default IndexPage
