import { css } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"
import { Col, Container, Row } from "react-grid-system"
import { Map, Placemark, YMaps } from "react-yandex-maps"
import bps from "../../utils/breakpoints"
import { primary } from "../../utils/variables"
import MessageButton from "../message"
import { RealButton } from "../UI/button"
import { H2, H3, HeadingWrapper } from "../UI/typography"
import { Heading } from "./howWeDoIt"

const Address = () => (
  <ContentWrapper>
    <Container>
      <Row>
        <Heading>
          <HeadingWrapper>
            <H2
              css={css`
                line-height: 22px;
              `}
            >
              Адрес производства
            </H2>
          </HeadingWrapper>
        </Heading>
        <MapWrapper>
          <YMaps>
            <Map
              style={{ width: "100%", height: "500px" }}
              defaultState={{ center: [57.605209, 39.903039], zoom: 13 }}
            >
              <Placemark geometry={[57.605209, 39.903039]}>KEK</Placemark>
            </Map>
          </YMaps>
        </MapWrapper>
        <Col sm={12}>
          <Row
            css={css`
              text-align: center;
              ${bps.md`text-align: left;`}
            `}
          >
            <AddressItem>
              <H3>Адрес производства:</H3>
              <p>
                150001, г. Ярославль
                <br /> ул. Вишняки, д. 1
              </p>
            </AddressItem>
            <AddressItem>
              <H3>Телефоны:</H3>
              <p>
                8 (961) 161-88-93 производство
                <br />8 (903) 820-24-73 производство
                <br />8 (4852) 75-72-53 бухгалтерия
              </p>
            </AddressItem>
            <AddressItem>
              <H3>E-mail:</H3>
              <p>info@smalta76.ru</p>
            </AddressItem>
          </Row>
        </Col>
        <Col
          sm={12}
          css={css`
            text-align: center;
            ${bps.md`text-align: left;`}
          `}
        >
          <MessageButton>
            <RealButton accent>
              Напишите нам
            </RealButton>
          </MessageButton>
        </Col>
      </Row>
    </Container>
  </ContentWrapper>
)

const ContentWrapper = styled.div`
  background: ${primary}66 no-repeat;
  padding-bottom: 40px;
  margin-bottom: 40px;
`

const MapWrapper = styled(props => <Col sm={12} {...props} />)`
  margin: 50px 0;
  display: flex;
  justify-content: center;
`

const AddressItem = styled(props => <Col sm={12} md={4} {...props} />)`
  margin-bottom: 40px;
`

export default Address
